import React, { useState, useRef, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, TileLayer, Marker, Tooltip, Popup } from 'react-leaflet';
import PropTypes from 'prop-types';
import { Button, LocationPin, CardResponsive } from '../';
import * as ReactDOM from 'react-dom/server';
import L from 'leaflet';
import Cookies from 'universal-cookie';
import { useMediaQuery } from 'react-responsive';
import getDistance from 'geolib/es/getDistance';
import { distanceActions } from '../../store/distance-slice';
import { currentPageActions } from '../../store/currentPage-slice';
import IonIcon from '@reacticons/ionicons';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

export const ResourceMap = ({ pins, activeScroll, resourceCounterOffset }) => {
  const { t } = useTranslation();

  const popupRef = useRef();
  const mapRef = useRef();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const cookies = new Cookies();
  const currentCoords = cookies.get('location');
  const locationCoords = useSelector((state) => state.coords.coordinates);
  const [map, setMap] = useState(null);

  const findMe = () => {
    const locationPermissionGranted = cookies.get('locationPermissionGranted');
    if (locationPermissionGranted === 'true' && currentCoords) {
      (() => {
        const map = mapRef.current;
        if (map == null) {
          return;
        }
        map.flyTo([currentCoords.lat, currentCoords.lng], 13);
      })();
    } else {
      const successCallback = (position) => {
        const cookies = new Cookies();
        cookies.set('locationPermissionGranted', 'true', { path: '/' });
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        cookies.set('location', JSON.stringify(location), { path: '/' });
        dispatch(distanceActions.toggle(true));
        return;
      };
      const errorCallback = (error) => {
        if (error.code === 1) {
          alert('Please allow your location access');
        }
      };
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
        enableHighAccuracy: true,
        timeout: 5000,
      });
    }
  };
  return (
    <>
      {pins ? (
        <MapContainer
          center={
            locationCoords
              ? [locationCoords.lat, locationCoords.lng]
              : [27.750354, -81.299718]
          }
          ref={mapRef}
          zoom={locationCoords ? 7 : 6}
          style={{ width: '100%', height: '100%' }}
        >
          {/* attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"


                        */}

          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <div className="search-page__buttons-wrapper--secondary">
            <IonIcon className="search-page__icon" name="navigate-outline" />
            <Button onClick={findMe} variant="secondary" size="small" label={t('Find Me')} />
          </div>

          {pins
            .filter((pin) => pin.latitude && pin.longitude)
            .map((r, index) => {
              return (
                <Marker
                  key={index}
                  position={[r.latitude, r.longitude]}
                  ref={popupRef}
                  eventHandlers={{
                    mouseover: (event) => event.target.openPopup(),
                    mouseout: (e) => {
                      setTimeout(() => {
                        e.target.closePopup();
                      }, 2000);
                    },
                  }}
                 
                  icon={L.divIcon({
                    className: '',
                    iconSize: [48, 48],
                    iconAnchor: [24, 48],
                    html: ReactDOM.renderToString(
                      <LocationPin
                        // number={10 * (currentPage - 1) + (index + 1)}
                        number={index + 1 + (resourceCounterOffset || 0)}
                      />
                    ),
                  })}
                >
                    {/* Popup on hover for desktop, tap for mobile */}
                    <Popup className="desktop-popup">
                      <CardResponsive
                        key={index}
                        id={r.resourceId}
                        header={r.name}
                        address={r.address}
                        website={r.website}
                        tags={r.tags}
                        index={index}
                        active
                        distance={
                          locationCoords && r.latitude
                            ? `${(
                              getDistance(
                                {
                                  latitude: locationCoords.lat,
                                  longitude: locationCoords.lng,
                                },
                                {
                                  latitude: r.latitude,
                                  longitude: r.longitude,
                                }
                              ) * 0.000621371
                            ).toFixed(1)}  miles away`
                            : ''
                        }
                      />
                    </Popup>
                </Marker>
              );
            })}
        </MapContainer>
      ) : (
        '...Loading...'
      )}
    </>
  );
};

ResourceMap.propTypes = {
  pins: PropTypes.array,
};

ResourceMap.defaultProps = {
  pins: [],
};
