import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import ErrorPage from './views/ErrorPage/ErrorPage';
import InitializeApp from './components/InitializeApp/InitializeApp';
import RequireAuth from './components/RequireAuth/RequireAuth';
import { Landing } from './views/Landing/Landing';
import { Search } from './views/Search/Search';
import SignIn from './views/SignIn/SignIn';
import { Resource } from './views/Resource/Resource';
import { Resource2 } from './views/Resource/Resource2';



import { Dashboard } from './components';
import { ResourceEditor } from './views/ResourceEditor/ResourceEditor';
import { useDispatch } from 'react-redux';
import { userActions } from './store/auth-slice';
import { RegistrationForm } from './views/Dashboard/RegistrationForm';
function RouteSwitch() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   onAuthStateChanged(getAuth(firebaseApp), async (user) => {
  //     if (user) {
  //       if (user.emailVerified) {
  //         const fetchUserData = async () => {
  //           const response = await getUserData({ uid: user.uid });
  //           return response;
  //         };
  //         const data = await fetchUserData();
  //         dispatch(userActions.setUser(data));
  //       }
  //     } else {
  //       dispatch(userActions.setUser(undefined));
  //     }
  //   });
  // }, []);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route element={<InitializeApp />}>
            {/* sign ins */}
            <Route path="/auth/signin" element={<SignIn />} />
            <Route path="/auth/signin/callback/google" element={<SignIn callbackIdP={'google'} />} />
            <Route path="/auth/signin/callback/ms365" element={<SignIn callbackIdP={'ms365'} />} />

            <Route path="/" element={<Landing />} />
            <Route path="/search" element={<Search />} />
            <Route path="/resource/:id" element={<Resource2 />} />

            {/* <Route path="/resource/:id/print" element={<ResourcePrint />} /> */}


            <Route element={<RequireAuth allowedRoles={['100001']} />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/addResource" element={<ResourceEditor />} />
              <Route path="/editResource" element={<ResourceEditor edit={true} />} />
            </Route>

            {/* catch all */}
            <Route path="*" element={<ErrorPage error="404" />} />
          </Route>
        </Routes>
      </BrowserRouter >
    </QueryClientProvider>
  );
}

export default RouteSwitch;
