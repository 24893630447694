import React, { Fragment, useEffect, useState } from 'react';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';
import Alert from '../../components/Alert/Alert';
import {
  Box,
  Container,
  Typography
} from '@mui/material';

const PendingApproval = () => {
  const { t } = useTranslation();

  const buttonTextStyle = {
    fontFamily: "Nunito",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 700,
    // lineHeight: 4
    textTransform: "none"
  }

  return (
    <Container maxWidth="lg" sx={{ marginY: '46px' }}>
      <Alert data={{
        severity: 'info',
        title: t('Pending Approval'),
        message: t('Your registration is successfully submitted and is pending approval from our team. We\'ll reach out to you should we need any additional information. We appreciate your patience!')
      }} />
    </Container>
  );
}

export { PendingApproval };