import React from 'react';
import PropTypes from 'prop-types';
import { Pills } from '../Pills/Pills';
import { Button } from '../Button/Button';
import { useNavigate } from 'react-router-dom';
export const CardResponsive = ({ header, address, distance, active, index, id, tags}) => {
  const navigate = useNavigate();
  function handleClick(e) {
    e.stopPropagation();
  }

  return (
    //Format both ways regardless of desktop and mobile
      <div className={`cardResponsive__desktop`}>
        <div className="cardResponsive__headerDesktop" onClick={() => navigate(`/resource/${id}`)}>{`${header}`}</div>

        {/*setup space for Verification */}
        <div className="cardResponsive__verificationDesktop">

          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 10 10" fill="none">
          <circle cx="5" cy="5" r="3.625" stroke="#2067D2" stroke-width="0.75"/>
          <path d="M3.24988 5L4.49988 6.25L6.74988 4" stroke="#2067D2" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span style={{ marginLeft: '0.3rem'}}>Verified</span>
        </div>
        
        <div className="cardResponsive__button-wrapperDesktop">

          {/*Tags*/}
          <div className="paragraphs-container">
          {tags.map((tag, index) => (
            <p key={index}>{tag.charAt(0).toUpperCase() + tag.slice(1)}</p>
          ))}
          </div>

          <>
          {(`${address?.street}` != `Online`) ? ( //Render nav button if not online

          <div className="cardResponsive__buttonDesktop" onClick={() => //Logic from Resource2 "handleOpenInMaps"
          {
            // Construct the Google Maps URL with destination coordinates
            const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(`${address?.street}, ${address?.city}, ${address?.state}, ${address?.zipCode}`)}`;
            // Open the URL in a new tab
            window.open(mapsUrl, '_blank');
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 10 10" fill="none">
            <g clip-path="url(#clip0_8782_7457)">
              <path d="M5 0.500003C4.86348 0.499668 4.72823 0.526249 4.602 0.578225C4.47576 0.630201 4.36101 0.706553 4.2643 0.802912L4.22103 0.856916L0.856217 4.22146L0.802208 4.26473C0.608586 4.46062 0.5 4.72494 0.5 5.00036C0.5 5.27579 0.608586 5.54011 0.802208 5.736L4.2643 9.19782C4.46021 9.39142 4.72455 9.5 5 9.5C5.27544 9.5 5.53979 9.39142 5.7357 9.19782L9.19779 5.736C9.39141 5.54011 9.5 5.27579 9.5 5.00036C9.5 4.72494 9.39141 4.46062 9.19779 4.26473L5.7357 0.802912C5.63899 0.706553 5.52424 0.630201 5.39801 0.578225C5.27177 0.526249 5.13652 0.499668 5 0.500003ZM5 1.19237C5.08794 1.19237 5.18003 1.22075 5.24892 1.28964L8.71102 4.75146C8.74378 4.7841 8.76978 4.82289 8.78752 4.86561C8.80526 4.90832 8.81439 4.95411 8.81439 5.00036C8.81439 5.04661 8.80526 5.09241 8.78752 5.13512C8.76978 5.17783 8.74378 5.21662 8.71102 5.24927L5.24892 8.71108C5.21628 8.74385 5.17748 8.76984 5.13477 8.78758C5.09205 8.80532 5.04625 8.81445 5 8.81445C4.95375 8.81445 4.90795 8.80532 4.86523 8.78758C4.82252 8.76984 4.78372 8.74385 4.75108 8.71108L1.28898 5.24927C1.25621 5.21662 1.23022 5.17783 1.21248 5.13512C1.19474 5.09241 1.18561 5.04661 1.18561 5.00036C1.18561 4.95411 1.19474 4.90832 1.21248 4.86561C1.23022 4.82289 1.25621 4.7841 1.28898 4.75146L4.75108 1.28964C4.82032 1.22075 4.91206 1.19237 5 1.19237ZM5.34621 3.26946V4.308H3.96137C3.77773 4.308 3.60161 4.38095 3.47176 4.51079C3.3419 4.64063 3.26895 4.81674 3.26895 5.00036V6.03891H3.96137V5.00036H5.34621V6.03891L6.73105 4.65418L5.34621 3.26946Z" fill="#4B4C51"/>
            </g>
            <defs>
              <clipPath id="clip0_8782_7457">
                <rect width="10" height="10" fill="white"/>
              </clipPath>
            </defs>
            </svg>
          </div>
          ) : ('')}
          </>

        </div>
    </div> 
  );
};

CardResponsive.defaultProps = {
  header: 'Header',
  address: 'Address street, City State Zip',
  distance: 'Distance from your location',
  website: 'website.com',
  phone: 'Phone number',
  openTime: '7AM',
  closeTime: '5PM',
};

CardResponsive.propTypes = {
  header: PropTypes.string,
  address: PropTypes.string,
  website: PropTypes.string,
  phone: PropTypes.string,
  distance: PropTypes.bool || PropTypes.string,
};
