import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { validateForm } from './formValidation';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Alert from '../../components/Alert/Alert';
import { axiosPrivate } from '../../apis/backend';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from '@mui/material';
import {
  Footer,
  Header,
  MyResourcesList,
  StyledDropzone,
  HoursModal,
  PendingResourcesList,
  UsersList,
  ApprovedResourcesList,
  Map,
} from '../../components';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';
import { userActions } from '../../store/auth-slice';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

// const defaultTheme = createTheme();

const MySavedResources = () => {

  const sendData = async (data) => {
    try {
      const response = await axiosPrivate.post('/user/savedResources', data);
     
    } catch (error) {
    }
  };

  sendData();




  return (
    <>
    

      {/* </ThemeProvider> */}
    </>
  );

};

export { MySavedResources };
