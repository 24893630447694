import React from 'react';
import './_Reviews.scss';
import { Button } from '../Button/Button';

import { Rating, Typography } from '@mui/material';

function Reviews({ reviewer, reviewText, rating }) {
  return (
    <div className="container">
      <div className="content-wrapper">
        {/*<div className="circle">
           Circle content, possibly an avatar or initial 
        </div>*/}
        <div className="content">
          <div className="box">
            <Typography style={{ fontWeight: 'bold', fontSize: 18 , color: '#2C2D30'}}>
              {reviewer}
            </Typography>
          </div>
        </div>
      </div>
      <Rating name="read-only" value={rating} readOnly />
      <div className="review-text">
        {reviewText}
      </div>
      <div className="button-group">
      {/* <Button
        variant="primary"
        label="Helpful"
       
        // onClick={handleClick}
      />
      <Button
        variant="secondary"
        label="Report"
        // onClick={handleClick}
      /> */}
        
      </div>
    </div>
  );
}

export default Reviews;


// ◦	name color for reviews
// ◦	button shape review
// ◦	notary papers